// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.onload = function() {
  const toggleAdditionalFieldsLink = document.querySelector('.toggle-additional-fields-link');
  if (toggleAdditionalFieldsLink) {
    const additionalFieldsContainer = document.querySelector('.additional-fields-container');
    toggleAdditionalFieldsLink.addEventListener('click', (event) => {
      event.preventDefault();
      if (additionalFieldsContainer) {
        additionalFieldsContainer.classList.toggle('hide-additional-fields');
      }
    });
  }

  const ndaForm = document.querySelector('.edit_nda');
  if (ndaForm) {
    let generationMode = true;

    const companyShortName = this.document.getElementById('nda_company_short_name');
    const projectTitleInput = document.getElementById('nda_project_name');
    const projectCategorySelect = document.getElementById('nda_project_category');
    const projectFocusHorizonSelect = document.getElementById('nda_project_focus_horizon');
    const projectFocusAreaSelect = document.getElementById('nda_project_focus_area');
    const projectDescriptionTextarea = document.getElementById('nda_project_description');

    function generateText() {
      if (generationMode == false) {
        generationMode = confirm(ndaForm.dataset.warningEnableGenerationMode);
      }
      if (generationMode) {
        let text = ndaForm.dataset.template;

        text = text.replace("$$COMPANY_SHORT_NAME$$", companyShortName.value);
        text = text.replace("$$PROJECT_TITLE$$", projectTitleInput.value);
        text = text.replace("$$PROJECT_CATEGORY$$", projectCategorySelect.value);
        text = text.replace("$$PROJECT_FOCUS_HORIZON$$", projectFocusHorizonSelect.value);
        text = text.replace("$$PROJECT_FOCUS_AREA$$", projectFocusAreaSelect.value);

        projectDescriptionTextarea.value = text;
      }
    }

    function disableGenerationMode() {
      if (generationMode) {
        generationMode = !confirm(ndaForm.dataset.warningDisableGenerationMode);
        if (generationMode) {
          generateText();
        }
      }
    }

    companyShortName.addEventListener("keyup", generateText);
    projectTitleInput.addEventListener("keyup", generateText);
    projectCategorySelect.addEventListener("change", generateText);
    projectFocusHorizonSelect.addEventListener("change", generateText);
    projectFocusAreaSelect.addEventListener("change", generateText);

    projectDescriptionTextarea.addEventListener("click", disableGenerationMode);

    generateText();
  }
};
